/**
 * @name: 分成管理-分成规则管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 分成管理-分成规则管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { ISplitRule, ISplitRuleParams, ISplitRuleReferring } from './types';


export const rulePageApi = (params: ISplitRuleParams) => api.get<IPageRes<ISplitRule[]>>('/admin/commission/manage/queryCommissionRuleByPage', params)

export const ruleExportApi = (params: ISplitRuleParams) => api.get('/admin/commission/manage/commissionRuleExport', params, 'blob')

export const ruleReferringListApi = (agentId: string) => api.get<ISplitRuleReferring[]>('/admin/commission/manage/queryIntroduceCommissionByList', { agentId })

export const ruleCooperatingListApi = (deviceId: string) => api.get('/admin/commission/manage/queryPartnerCommissionByList', {deviceId})

export const ruleCooperatingQueryApi = (keywords?: string) => api.get('/admin/commission/manage/queryPartnerByList', {keywords})

export const ruleCooperatingModifyApi = (data: any) => api.postJ('/admin/commission/manage/partnerCommissionModify', data)
