
/**
 * @name: 分成管理-分成规则管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 分成管理-分成规则管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  rulePageApi,
  ruleExportApi,
  ruleReferringListApi,
  ruleCooperatingListApi,
  ruleCooperatingQueryApi,
  ruleCooperatingModifyApi
} from '@/apis/split/rule'
import {
  ISplitRule,
  ISplitRuleParams,
  ISplitRuleReferring
} from '@/apis/split/rule/types'
import Big from 'big.js'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from '@/utils/common'

@Component({})
export default class splitRule extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISplitRule[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISplitRuleParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ISplitRule> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: '设备编号',
        prop: 'deviceSn',
        align: 'center',
        search: true
      },
      {
        label: '所属代理商',
        prop: 'agentName',
        align: 'center',
        search: true
      },
      {
        label: '代理商分成比例',
        prop: 'agentCommission',
        align: 'center',
        slot: true
      },
      {
        label: '平台分成比例',
        prop: 'platformCommission',
        align: 'center',
        slot: true
      },
      {
        label: '介绍人分成比例',
        prop: 'introduceCommission',
        align: 'center',
        slot: true
      },
      {
        label: '业务合作方分成比例',
        prop: 'partnerCommission',
        align: 'center',
        slot: true
      }
    ]
  }

  referringDialog = false

  referringList: ISplitRuleReferring[] = []

  cooperatingDialog = false

  cooperatingList: any = []


  editCooperatingDialog = false

  selectCooperatingId = ''

  selectCooperatingObj: any = {}

  selectCooperatingList: any = []

  cooperatingLoading = false

  cooperatingSearchList: any = []


  selectRow: any = {}

  cooperatingQuery (query?: string) {
    this.cooperatingLoading = true
    ruleCooperatingQueryApi(query).then(e => {
      if (e && e.data) {
        const ids = this.selectCooperatingList.map((item: any) => item.partnerId)
        this.cooperatingSearchList = e.data.filter((item: any) => !ids.includes(item.id)).map((item: any) => {
          return {
            ...item,
            partnerId: item.id,
            partnerPersonInCharge: item.personInCharge,
            partnerPhone: item.phone,
            commission: ''
          }
        })
      }
    }).finally(() => {
      this.cooperatingLoading = false
    })
  }

  cooperatingChange (val: string) {
    if (val) {
      this.selectCooperatingObj = this.cooperatingSearchList.find((item: any) => item.partnerId === val)
    } else {
      this.selectCooperatingObj = {}
    }
  }

  addCooperating () {
    if (!this.selectCooperatingId || !this.selectCooperatingObj || !this.selectCooperatingObj.partnerId) {
      return this.$message.warning('请选择业务合作方')
    }
    const obj: any = deepCopy(this.selectCooperatingObj)
    obj.agentId = this.selectRow.agentId
    this.selectCooperatingList.push(obj)

    this.selectCooperatingObj = {}
    this.selectCooperatingId = ''
    this.cooperatingSearchList = []
    this.cooperatingQuery()
  }

  removeCooperating (index: number) {
    this.selectCooperatingList.splice(index, 1)

    this.selectCooperatingId = ''
    this.selectCooperatingObj = {}
    this.cooperatingSearchList = []
    this.cooperatingQuery()
  }

  getList () {
    this.tableLoading = true

    rulePageApi(this.queryParam).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.tableData = e.data.list
        this.tableTotal = e.data.total
      }
    })
  }

  exportExcel () {
    ruleExportApi(this.queryParam).then(e => {
      if (e && e.data) {
        exportFile(e.data, '分成规则.xlsx')
        this.$message.success('操作成功!')
      }
    })
  }

  openReferringDialog (row: ISplitRule) {
    ruleReferringListApi(row.agentId).then(e => {
      if (e && e.data) {
        this.referringList = e.data
        this.referringDialog = true
      }
    })
  }

  openCooperatingDialog (row: ISplitRule) {
    ruleCooperatingListApi(row.deviceId).then(e => {
      if (e && e.data) {
        this.cooperatingList = e.data
        this.cooperatingDialog = true
      }
    })
  }

  openEdit (row: ISplitRule) {
    this.cooperatingSearchList = []
    this.selectCooperatingId = ''
    this.selectCooperatingObj = {}

    this.selectRow = deepCopy(row)

    ruleCooperatingListApi(row.deviceId).then(e => {
      if (e && e.data) {
        this.selectCooperatingList = deepCopy(e.data)
        this.cooperatingQuery()
        this.editCooperatingDialog = true
      }
    })
  }

  rowEdit (done: Function, loading: Function) {
    let arr: any = []
    if (this.selectCooperatingList && this.selectCooperatingList.length) {
      // 有数据
      let sum = new Big(0)
      for (let i = 0; i < this.selectCooperatingList.length; i++) {
        const item = this.selectCooperatingList[i]
        if (!/^\d+(\.\d{1,2})?$/.test(String(item.commission)) || item.commission == 0 || parseFloat(String(item.commission)) >= 100) {
          this.$message.error(`业务合作方“${this.selectCooperatingList[i]['partnerName']}”分成比例格式不正确`)
          loading()
          return;
        }
        sum = sum.plus(item.commission)
      }
      if (sum.toNumber() >= parseFloat(this.selectRow.agentCommission)) {
        this.$message.error('业务合作方分成比例之和不能大于等于代理商分成比例')
        loading()
        return;
      }
      arr = this.selectCooperatingList.map((item: any) => {
        return {
          agentId: item.agentId,
          partnerId: item.partnerId,
          deviceId: this.selectRow.deviceId,
          commission: item.commission
        }
      })
    }
    ruleCooperatingModifyApi({deviceId: this.selectRow.deviceId, list: arr}).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getStatusText (status: number) {
    if (status == 1) {
      return '启用'
    }
    if (status == 2) {
      return '禁用'
    }
  }

  rowClose () {

  }

  created () {
    this.getList()
  }
}
